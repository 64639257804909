import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const OpenModalButton = styled(motion.button)`
  font-size: 1rem;
  padding: 8px 20px;
  border: none;
  background-color: ${(props) => props.theme.colors.primary[900]};
  color: white;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`
const animatedOpenButton = ({ children, handlClick }) => {
  return (
    <OpenModalButton
      onClick={handlClick}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      {children}
    </OpenModalButton>
  )
}

export default animatedOpenButton
