import styled, {
  space,
  color,
  layout,
  typography,
  propTypes,
  position,
  variant,
  compose,
} from '@style'

const textVariants = variant({
  variants: {
    h1: {
      fontWeight: 'regular',
      lineHeight: 'solid',
      fontSize: [5, 6],
    },
    overline: {
      fontWeight: 'regular',
      lineHeight: 'solid',
      fontSize: 2,
      textTransform: 'uppercase',
      letterSpacing: 'labels',
    },
    body: {
      fontWeight: 'regular',
      lineHeight: 'copy',
      fontSize: [3, 2],
    },
    caption: {
      fontWeight: 'medium',
      lineHeight: 'copy',
      fontSize: 2,
    },
    label: {
      fontWeight: 'regular',
      lineHeight: 'solid',
      fontSize: 1,
    },
  },
})

export const Text = styled.p`
  ${compose(space, color, typography, layout, position)}
  ${textVariants}
`

Text.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.position,
}

export default Text
