import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Navlink = styled(AnchorLink)`
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary[900]};
  &:hover {
    opacity: 0.8;
  }
`
export default Navlink
