import React, { useState } from 'react'
import styled from '@style'
import * as Yup from 'yup'
import { Formik } from 'formik'
import axios from 'axios'
import { motion } from 'framer-motion'
import { Navlink } from '@components/Link'
import { Box, Flex } from '@components/Grid'
import Checkbox from '../Checkbox'
import { useSnackbar } from 'notistack'

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const TermsLink = styled(Navlink)`
  padding-bottom: 0px;
  margin-bottom: 0px;
`
const Input = styled.input`
  min-width: 300px;
  height: 35px;
  border: 2px solid ${(props) => props.theme.colors.primary[600]};
  background-color: #fff;
`
const StyledTextarea = styled.textarea`
  border: 2px solid ${(props) => props.theme.colors.primary[600]};
  display: block;
  line-height: 25px;
  min-height: 120px;
  overflow: hidden;
  resize: none;
  width: 100%;
  @media (max-width: 768px) {
    min-height: 100px;
  }
`
const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: #000;
  font-size: 0.8em;
  margin: 0.2em 0;
  position: relative;
`
const ButtonOnModal = styled(motion.button)`
  font-size: 1rem;
  padding: 8px 20px;
  border: none;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  background: ${(props) =>
    props.disabled ? 'grey' : props.theme.colors.primary[900]};
  color: ${(props) => (props.disabled ? 'black' : 'white')};
`

const FormZapier = ({ handleClose, isDisabled, setIsDisabled }) => {
  const { enqueueSnackbar } = useSnackbar()
  const url = 'https://hooks.zapier.com/hooks/catch/6485212/boxysjg/'
  const url_general = 'https://hooks.zapier.com/hooks/catch/6485212/boxysjg/'
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const [checked, setCheck] = useState(false)
  const handleCheckboxChange = (event) => {
    setCheck(event.target.checked)
  }
  return (
    <Formik
      initialValues={{
        name: '',
        lastName: '',
        email: '',
        phone: '',
        ref: '',
        date: '',
        type: 'Contact Form',
        cv: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(100).required('Campo debe estar completado.'),
        lastName: Yup.string()
          .max(100)
          .required('Campo debe estar completado.'),
        email: Yup.string()
          .email('Debe ser un correo valido')
          .max(200)
          .required('Correo requerido'),
        ref: Yup.string()
          .max(300)
          .required('Es necesario explicar este punto.'),
        phone: Yup.number()
          .test('len', 'Deben ser 9 digitos exactamente.', (val) =>
            val ? val.toString().length === 9 : ''
          )
          .required('Campo requerido.'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          let date = new Date()
          const objAplication = {
            name: values.name,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            ref: values.ref,
            date: date,
            type: values.type,
            cv: values.cv,
          }
          console.log(objAplication)
          await axios.post(url, objAplication, { headers: headers })
          console.log('axios post')
          enqueueSnackbar(
            'Tu solicitud ha sido enviada, te notificaremos cuando este aprobada. ',
            {
              variant: 'Success',
            }
          )
          setStatus({ success: true })
          setSubmitting(false)
        } catch (error) {
          setStatus({ success: false })
          setErrors({ submit: error.message })
          setSubmitting(false)
          enqueueSnackbar('Hubo un error, intenta de nuevo.', {
            variant: 'error',
          })
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection="column">
            <Flex mb={1} flexDirection={['column', 'row']}>
              <Box px={1} pb={2}>
                <Label>
                  Nombre(s)
                  {touched.name && errors.name && (
                    <Label variant="label" color="red">
                      {errors.name}
                    </Label>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    border={touched.name && errors.name && '1px solid red'}
                    type="text"
                    name="name"
                  />
                </Label>
              </Box>
              <Box px={1} pb={2}>
                <Label>
                  Apellido(s)
                  {touched.lastName && errors.lastName && (
                    <Label variant="label" color="red">
                      {errors.lastName}
                    </Label>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    border={
                      touched.lastName && errors.lastName && '1px solid red'
                    }
                    type="lastName"
                    name="lastName"
                  />
                </Label>
              </Box>
            </Flex>
            <Flex mb={1} flexDirection={['column', 'row']} alignItems="center">
              <Box px={1} pb={2}>
                <Label>
                  Email
                  {touched.email && errors.email && (
                    <Label variant="label" color="red">
                      {errors.email}
                    </Label>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    border={touched.email && errors.email && '1px solid red'}
                    type="text"
                    name="email"
                  />
                </Label>
              </Box>
              <Box px={1} pb={2}>
                <Label>
                  Teléfono
                  {touched.phone && errors.phone && (
                    <Label variant="label" color="red">
                      {errors.phone}
                    </Label>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    border={touched.phone && errors.phone && '1px solid red'}
                    type="number"
                    name="phone"
                  />
                </Label>
              </Box>
            </Flex>
            <Box px={1}>
              <Label>
                Escríbenos tu mensaje.
                {touched.ref && errors.ref && (
                  <Label variant="label" color="red">
                    {errors.ref}
                  </Label>
                )}
                <StyledTextarea
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ref}
                  border={touched.ref && errors.ref && '1px solid red'}
                  type="ref"
                  name="ref"
                />
              </Label>
            </Box>
          </Flex>
          <Box py={2}>
            <label>
              <Checkbox checked={checked} onChange={handleCheckboxChange} />
              <span style={{ marginLeft: 8, marginBottom: 8, color: 'black' }}>
                Acepto los{' '}
                <TermsLink color="primary.900" to="/es/termsConditions">
                  Términos y condiciones
                </TermsLink>{' '}
                y{' '}
                <TermsLink color="primary.900" to="/es/privacyPolicies">
                  Aviso de Privacidad
                </TermsLink>
              </span>
            </label>
          </Box>
          <ButtonOnModal
            type="submit"
            onClick={handleClose}
            disabled={!checked || isDisabled ? true : false}
          >
            Aplicar
          </ButtonOnModal>
          {setIsDisabled(!(isValid && dirty))}
        </Form>
      )}
    </Formik>
  )
}

export default FormZapier
