/* eslint-disable prefer-destructuring */
/**
 * This is our custom theme where we define global styles.
 * It should serve as a guideline for styling, but not all styles *have* to be taken from here.
 */
const breakpoints = ['576px', '768px', '992px', '1200px']

//  Aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

/**
 * Primary: Colors to use for actionable items, such as links, buttons etc.
 * Grey: Colors for items that are not that important
 */
const colors = {
  pageBackground: 'hsl(228, 33%, 97%)',
  white: 'hsl(0, 0%, 100%)',
  black: 'hsl(0, 0%, 0%)',
  primary: {
    100: 'hsl(221, 21%, 91%)',
    200: 'hsl(221, 21%, 82%)',
    300: 'hsl(221, 21%, 73%)',
    400: 'hsl(221, 21%, 64%)',
    500: 'hsl(221, 21%, 55%)',
    600: 'hsl(221, 21%,, 46%)',
    700: 'hsl(221, 21%, 37%)',
    800: 'hsl(221, 21%,, 28%)',
    // 900: 'hsl(221, 21%, 29%)', // main
    900: '	hsl(218, 65%, 29%)', // main Aibrok
  },
  grey: {
    100: 'hsl(210, 17%, 98%)',
    200: 'hsl(207, 22%, 90%)',
    300: 'hsl(210, 24%, 87%)',
    400: 'hsl(208, 17%, 83%)',
    500: 'hsl(209, 24%, 74%)',
    600: 'hsl(215, 17%, 63%)',
    700: 'hsl(214, 11%, 49%)',
    800: 'hsl(215, 17%, 30%)',
    900: 'hsl(215, 23%, 16%)',
  },
  red: {
    100: 'hsl(360, 77%, 95%)',
    200: 'hsl(360, 79%, 81%)',
    300: 'hsl(360, 71%, 73%)',
    400: 'hsl(360, 70%, 64%)',
    500: 'hsl(360, 71%, 53%)',
    600: 'hsl(360, 65%, 45%)',
    700: 'hsl(360, 61%, 38%)',
    800: 'hsl(360, 63%, 31%)',
    900: 'hsl(360, 60%, 24%)',
  },
  blue: {
    100: 'hsl(175, 64%, 97%)',
    200: 'hsl(175, 64%, 95%)',
    300: 'hsl(175, 64%, 92%)',
    400: 'hsl(175, 64%,, 89%)',
    // 500: 'hsl(175, 64%, 68%)', // main
    500: 'hsl(194, 57%, 90%)', // main Aibrok
    600: 'hsl(175, 64%,, 78%)',
    700: 'hsl(175, 64%, 69%)',
    800: 'hsl(175, 64%, 60%)',
    900: 'hsl(175, 64%,, 52%)',
  },
  green: {
    100: 'hsl(142, 81%, 94%)',
    200: 'hsl(141, 67%, 80%)',
    300: 'hsl(145, 65%, 68%)',
    400: 'hsl(146, 57%, 65%)',
    500: 'hsl(145, 55%, 49%)',
    600: 'hsl(145, 55%, 41%)',
    700: 'hsl(145, 59%, 33%)',
    800: 'hsl(155, 63%, 26%)',
    900: 'hsl(156, 61%, 20%)',
  },
}

/**
 * Space is used for margin and padding scales.
 * It's recommended to use powers of two to ensure alignment across the entire project
 */
const space = [
  '0',
  '4px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px',
  '80px',
  '128px',
  '256px',
  '512px',
]

/**
 * Typographic scale
 */
const fontSizes = [
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '32px',
  '40px',
  '64px',
  '96px',
  '128px',
]

const lineHeights = {
  solid: 1.15,
  title: 1.2,
  copy: 1.5,
}

const fontWeights = {
  regular: 400,
  semibold: 600,
}

/**
 * Letter-spacing should vary, depending on usage of text
 */
const letterSpacings = {
  normal: 'normal',
  caps: '0.25em',
  labels: '0.05em',
}

/**
 * Border-radius
 */
const radii = ['0', '2px', '4px', '8px', '16px']

export const theme = {
  name: 'Default',
  breakpoints,
  colors,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
}
