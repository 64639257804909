import styled from 'styled-components'

const LinkFun = styled.a`
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary[900]};
  &:hover {
    cursor:pointer;
    opacity: 0.8;
  }
`
export default LinkFun
