/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react'
import styled from '@style'
import { useDropzone } from 'react-dropzone'
import { Box, Flex } from '@components/Grid'
import { Text } from '@components/Text'
import LogoDesktop from '@images/Logo/aibrok1.png'
import { getSignedUrl, upload, arrayHasValues } from '../../util/AWS'

const DropZone = styled.div`
  border: 2px solid ${(props) => props.theme.colors.primary[600]};
  padding: 20px;
  outline: 'none';
  display: 'flex';
  justify-content: 'center';
  flex-wrap: 'wrap';
  align-items: 'center';
  cursor: 'pointer';
  &:hover {
    background-color: hsl(243, 52%, 82%);
    opacity: 0.5;
  }
  &:active {
    background-color: hsl(243, 52%, 82%);
    opacity: 0.5;
  }
  @media (max-width: 768px) {
    max-height: 100px;
    padding: 0px;
  }
`
const Image = styled.img`
  width: 150px;
`
const InsideText = styled(Text)`
  margin: 0px;
  @media (max-width: 768px) {
    font-size: 0.8em;
    padding: 0px;
    margin: 0px;
  }
`
const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: #000;
  font-size: 0.8em;
  margin: 0.2em 0;
  position: relative;
`

function MyDropZone({ typeImage, uploadImage, dropFile, selectFiles, label }) {
  const [files, setFiles] = useState()
  console.log(files)
  const handleDrop = useCallback(
    async (acceptedFiles) => {
      console.log(acceptedFiles)
      if (arrayHasValues(acceptedFiles)) {
        acceptedFiles.map((acceptedFile) => {
          console.log(acceptedFile)
          const file = acceptedFile
          const lastIndex = acceptedFile.name.replace(' ', '').lastIndexOf('.')
          const fileName = acceptedFile.name.substr(0, lastIndex)
          const fileType = acceptedFile.name.substr(lastIndex)
          const fileSize = acceptedFile.size
          const folder = `CV_SAFEBROK`
          const path = `${folder}/${fileName}${fileType}`

          upload(path, file)
            .then((s3) => {
              console.log('s3', s3)
              getSignedUrl(s3.Location)
                .then((url) => {
                  const image = {
                    name: fileName,
                    size: fileSize,
                    url: s3.Location,
                  }
                  uploadImage(s3.Location, typeImage)
                  setFiles(image)
                })
                .catch((error) => {
                  console.log(error)
                })
            })
            .catch((error) => {
              console.log(error)
            })
        })
      }
    },
    [files]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: '.png, .jpg, .svg, .pdf, .jpeg',
  })

  return (
    <div style={{ margin: ' 10px 5px' }}>
      <Label>{label}</Label>
      <DropZone {...getRootProps()}>
        <input {...getInputProps()} accept=".png,.jpg, .svg, .pdf" />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width={['100%', 'initial']}
          p={1}
        >
          <div>
            <Image alt="Select file" src={LogoDesktop} />
          </div>
          <div>
            <InsideText variant="h3" color="primary.900">
              {selectFiles}
            </InsideText>
            <Box>
              <InsideText variant="body" color="primary.900">
                {dropFile}
              </InsideText>
            </Box>
          </div>
        </Flex>
      </DropZone>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width={['100%', 'initial']}
        p={1}
      >
        <Image src={files?.url} />
        <InsideText variant="h3" color="primary.900">
          {files?.name}
        </InsideText>
      </Flex>
    </div>
  )
}

export default MyDropZone
