import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Box } from '@components/Grid'
import { Text } from '@components/Text'
import { Contact, JoinUs } from '@components/Form'
import LogoMobile from '@images/Logo/aibrok1.png'

const InsideText = styled(Text)`
  @media (max-width: 768px) {
    font-size: 0.8em;
    padding: 0px;
    margin: 0px;
  }
`
const Image = styled.img`
  width: 100%;
  height:80px;
  @media (max-width: 768px) {
   width: 20px
   height:20px;
  }
`
const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
`
const ModalBox = styled.div`
  height: 92vh;
  overflow-y: auto;
`
const ModalContainer = styled(motion.div)`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: initial !important;
`
const CloseButton = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
`
const ModalContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`
const ModalHeader = styled(Box)``

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
}
const containerVariant = {
  initial: { top: '-50%', transition: { type: 'spring' } },
  isOpen: { top: '50%' },
  exit: { top: '-50%' },
}
const Modal = ({ contact, handleClose, children, isOpen }) => {
  const [logo, setLogo] = useState()
  const [isDisabled, setIsDisabled] = useState(true)
  const uploadImage = (image, type) => {
    console.log('image', image)

    setLogo(image)
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          initial="initial"
          animate="isOpen"
          exit="exit"
          variants={modalVariant}
        >
          <ModalContainer variants={containerVariant}>
            <ModalBox>
              <ModalHeader px={3} pt={1}>
                <Box p={2} display="flex" alingItems="center">
                  <Box p={2} display="flex">
                    <Image src={LogoMobile} alt="" />
                  </Box>
                  <Box p={1}>
                    <InsideText as="h3" color="primary.900">
                      Gracias por tu interés.
                    </InsideText>
                    <InsideText variant="body" color="primary.900">
                      Envía tus datos de contacto para comunicarnos contigo.
                    </InsideText>
                  </Box>
                </Box>
              </ModalHeader>
              <CloseButton
                onClick={handleClose}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20.39 20.39"
              >
                <title>close</title>
                <line
                  x1="19.39"
                  y1="19.39"
                  x2="1"
                  y2="1"
                  fill="none"
                  stroke="#5c3aff"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="1"
                  y1="19.39"
                  x2="19.39"
                  y2="1"
                  fill="none"
                  stroke="#5c3aff"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
              </CloseButton>
              <ModalContent>
                {contact === true ? (
                  <Contact
                    handleClose={handleClose}
                    logo={logo}
                    uploadImage={uploadImage}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                  />
                ) : (
                  <JoinUs
                    handleClose={handleClose}
                    logo={logo}
                    uploadImage={uploadImage}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                  />
                )}
              </ModalContent>
            </ModalBox>
          </ModalContainer>
        </Overlay>
      )}
    </AnimatePresence>
  )
}

export default Modal
