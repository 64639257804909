import styled from '@style'

import { Text } from './Text'

const SectionTitle = styled(Text)`
  color: ${(props) => props.theme.color};
  border-bottom: 1px solid ${(props) => props.theme.color};
  font-size: ${(props) => props.theme.fontSizes[4]};
  padding-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 500;
`
export default SectionTitle
