import S3 from 'aws-sdk/clients/s3'

const BUCKET = 'safebrok-cv'
const REGION = 'us-east-2'

const s3 = new S3({
  apiVersion: '2006-03-01',
  region: REGION,
  credentials: {
    accessKeyId: 'AKIAVEQTEEYHWVZENPVX',
    secretAccessKey: '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',
  },
})

/**
 * Get signed URL
 *
 * @param {string} path
 */
export const getSignedUrl = async (path) => {
  path = path.replace(`https://${BUCKET}.s3.${REGION}.amazonaws.com/`, '')

  const params = {
    Bucket: BUCKET,
    Key: path,
    Expires: 600,
  }

  return s3.getSignedUrl('getObject', params)
}

/**
 * Upload file to AWS S3
 *
 * @param {string} path
 * @param {(Buffer|Uint8Array|Blob|string|Readable)} file
 */
export const upload = async (path, file) => {
  const params = {
    Body: file,
    Bucket: BUCKET,
    Key: path,
    ACL: 'public-read',
  }

  return s3.upload(params).promise()
}
