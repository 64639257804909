import React from 'react'
import PropTypes from 'prop-types'
import styled, { color as propsColor, propTypes as Props } from '@style'
import GoogleAnalytics from 'react-ga'
import { Link as GatsbyLink } from 'gatsby'

const StyledLink = styled(GatsbyLink)`
  ${propsColor}
  text-decoration: none;
  &.active {
    ${propsColor}
  }
`

const Link = ({ to, from, children, className, color }) => (
  <StyledLink
    color={color}
    activeClassName="active"
    to={to}
    className={className}
    onClick={() => {
      GoogleAnalytics.event({
        category: 'Link',
        action: `[clicked] ${from}`,
        label: to,
      })
    }}
  >
    {children}
  </StyledLink>
)

Link.propTypes = {
  to: PropTypes.string.isRequired,
  from: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  ...Props.propsColor,
}

Link.defaultProps = {
  className: ``,
  from: `unnamed link`,
}

export default Link
